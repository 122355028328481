export const generateRandomColour = () => {
  var letters = '0123456789ABCDEF'
  var color = '#'
  for (var i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)]
  }
  return color + '80'
}

export const colourSpinner = (color, range) => {
  const baseColour = color
  const divisions = 20
  const colours = []
  for (var i = 0; i < range; i++) {
    const value = 99 - i * divisions
    const newColour = value < 10 ? `0${value}` : value
    colours.push(`${baseColour}${newColour}`)
  }
  return colours
}

export const blueColourConstants = [
  '#6C8093',
  '#4D6D8E',
  '#22364B',
  '#2C4762',
  '#365779',
]

export const colours = [
  '#25D1A2',
  '#FF4560',
  '#005199',
  '#42B2F7',
  '#D28BEB',
  '#FFCD4C',
  '#949494',
  '#0F5499',
  '#4caf50',
  '#ff9900',
]

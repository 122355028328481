<script>
import { Doughnut } from 'vue-chartjs'
export default {
  name: 'PieChart',
  mixins: [Doughnut],
  props: {
    chartData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      options: {
        cutoutPercentage: 70,
        responsive: true,
        maintainAspectRatio: false,
        legend: false,
      },
    }
  },
  mounted() {
    this.renderChart(this.chartData, this.options)
  },
  watch: {
    chartData: {
      handler(newChartData) {
        this.renderChart(newChartData, this.options)
      },
    },
  },
}
</script>
